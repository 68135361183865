import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyAe1zV87_vUNoPtar0kI2Ro6mLQFJqXShs",
    authDomain: "environment-control-a5256.firebaseapp.com",
    projectId: "environment-control-a5256",
    storageBucket: "environment-control-a5256.appspot.com",
    messagingSenderId: "332775514027",
    appId: "1:332775514027:web:d1f0163b2d220a0216fcee",
    measurementId: "G-GCDNW0JC6T"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

export const fireViews = view => {
    console.log(view)
    db.collection("views").add({
        view,
        created: firebase.firestore.FieldValue.serverTimestamp()
    });
};
export const setMeetingLink = link => {
    console.log(link)
    db.collection("links").add({
        link,
        created: firebase.firestore.FieldValue.serverTimestamp()
    });
};