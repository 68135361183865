import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { db, setMeetingLink } from "../../firebase.config";
import { useEffect, useState } from "react";
import { useUpdateEffect } from 'react-use';
import Iframe from 'react-iframe'
import Loading from "../../utilities/Loading";
import { Zoom } from "react-toastify";
import Meeting from "../meeting/Meeting";

const ViewMSTeams = () => {
  //  setMeetingLink()
  const [meetingUrl, setMeetingUrl] = useState("https://www.youtube.com/embed/UoWHXrmIszg")
  const [showEnv, setShowEnv] = useState(false)
  const [showMeeting, setShowMeeting] = useState(true)
  let is_First_read = false;

  // Listen for changes in the 'meetingUrl' state
  // useEffect(() => {
  //   console.log("gggggggggggggggggggggggggggg")
  // }, [meetingUrl]);

  // Firestore query to listen for changes
  useEffect(() => {
    const unsubscribe = db.collection("views")
      .orderBy("created", "desc")
      .limit(1)
      .onSnapshot((snapshot) => {
        snapshot.forEach(function (doc) {
          console.log(doc.data().view);
          console.log(doc.data());
          is_First_read && toggleView(doc.data().view);
          is_First_read = true;
          console.log(doc.data().view);
        });
      });

    // Cleanup function to unsubscribe from the snapshot listener
    return () => unsubscribe();
  }, []);


  // links

  useEffect(() => {
    const unsubscribe = db.collection("links")
      .orderBy("created", "desc")
      .limit(1)
      .onSnapshot((snapshot) => {
        snapshot.forEach(function (doc) {
          console.log(doc.data().link);

          // is_First_read && setMeetingUrl(doc.data().link)
          // is_First_read = true;

        });
      });

    // Cleanup function to unsubscribe from the snapshot listener
    return () => unsubscribe();
  }, []);

  console.log(meetingUrl)

  const toggleView = (view_to_show) => {
    if (view_to_show === "teams-view") {
      setShowEnv(true)
      setShowMeeting(false)
    } else {
      setShowEnv(false)
      setShowMeeting(true)
    }
  }

  return (
    <div className="">
      <section id="main_wrapper">
        {showMeeting ?
          <Iframe url="https://team-sdk-control.nexus-ecosystem.com/?teamsLink=https%3A%2F%2Fteams.microsoft.com%2Fl%2Fmeetup-join%2F19%253ameeting_Zjk1MjY1MTItZjQxOS00ZTYzLWI1ZjUtMzg1MzMwNTRlZDk4%2540thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522ff9c7474-421d-4957-8d47-c4b64dec87b5%2522%252c%2522Oid%2522%253a%252201dcc255-8ea9-433d-a7ea-c1d8d748f3ed%2522%257d"
            width="100%"
            id="teams"
            className=""
            height="100%"
            loading={"...Please wait while loading your Meeting!"}
          />
          : null
        }

        {showEnv ?
          <div id="page_1" className=" teams w-full h-full ">
            <Iframe url="https://demo.360-simulation.com/Environment%2028/"
              width="100%"
              id="teams"
              className=""
              height="100%"
              loading={"...Please wait while loading your Meeting!"}
            />

          </div>
          : null
        }
      </section>
    </div>
  )
}

export default ViewMSTeams
