import { useState } from "react";
import { db, fireViews, setMeetingLink } from "../../firebase.config";
import { useEffect } from "react";
const Admin = () => {

  const [curr_view, setCurr_view] = useState("teams-view")
  const [switchBtnText, setSwitchBtnText] = useState("Environment")
  const [meetingUrl, setMeetingUrl] = useState("https://www.youtube.com/embed/UoWHXrmIszg")


  // console.log("111111111111",curr_view)

  useEffect(() => {
    // console.log("222222222222",curr_view)
    setSwitchBtnText(curr_view === "env-view" ? "MS Teams" : "Environment")
    // console.log("3333333333333", curr_view)

  }, [curr_view])
  const switchViews = (params) => {
    // console.log("switchViews")
    setCurr_view(curr_view === "teams-view" ? "env-view" : "teams-view");
    // console.log(curr_view, "ccccccccc", switchBtnText)
    fireViews(curr_view);
  }
console.log(meetingUrl)
  const addMeetingLink = (params) => {
    if (!meetingUrl) {
      console.log("enter val ");
      
      return false
    }else{
      setMeetingLink(meetingUrl)
    }
  
}


  return (
      <section id="main_wrapper" className="justify-evenly" >
        {/* <div className="flex justify-center items-center flex-col gap-3 ">
        <button id="add_meeting_link" onClick={addMeetingLink} type="button" className="btn-xl btn">Add Meeting Link</button>
          <input type="text" onChange={(e)=>setMeetingUrl(e.target.value)} placeholder="Type here" className="input input-bordered w-full max-w-xs" />
          <p className="text-red-600 text-sm "> {} </p>
        </div> */}
        <div className="switch_btn_wrapper flex-col gap-3">
          <h1 className="text-5xl font-semibold "> Control views  </h1>
          <button id="switch_btn" type="button" onClick={switchViews} className="btn-xl btn">{switchBtnText}</button>
        </div>
      </section>
  )
}

export default Admin