
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { db, setMeetingLink } from "../../firebase.config";
import { useEffect, useState } from "react";
import { useUpdateEffect } from 'react-use';
import Iframe from 'react-iframe'
import Loading from "../../utilities/Loading";
import { Zoom } from "react-toastify";
import Meeting from "../meeting/Meeting";
import './collapes.css'
import { FunctionalIFrameComponent } from "./FunctionalIFrameComponent";
const Collapse = () => {
  //  setMeetingLink()
  const [meetingUrl, setMeetingUrl] = useState("https://www.youtube.com/embed/UoWHXrmIszg")
  const [showEnv, setShowEnv] = useState(false)
  const [showMeeting, setShowMeeting] = useState(true)
  let is_First_read = false;

  // Listen for changes in the 'meetingUrl' state
  // useEffect(() => {
  //   console.log("gggggggggggggggggggggggggggg")
  // }, [meetingUrl]);

  // Firestore query to listen for changes
  useEffect(() => {
    const unsubscribe = db.collection("views")
      .orderBy("created", "desc")
      .limit(1)
      .onSnapshot((snapshot) => {
        snapshot.forEach(function (doc) {
          console.log(doc.data().view);
          console.log(doc.data());
          is_First_read && toggleView(doc.data().view);
          is_First_read = true;
          console.log(doc.data().view);
        });
      });

    // Cleanup function to unsubscribe from the snapshot listener
    return () => unsubscribe();
  }, []);


  // links

  useEffect(() => {
    const unsubscribe = db.collection("links")
      .orderBy("created", "desc")
      .limit(1)
      .onSnapshot((snapshot) => {
        snapshot.forEach(function (doc) {
          console.log(doc.data().link);

          // is_First_read && setMeetingUrl(doc.data().link)
          // is_First_read = true;

        });
      });

    // Cleanup function to unsubscribe from the snapshot listener
    return () => unsubscribe();
  }, []);

  console.log(meetingUrl)

  const toggleView = (view_to_show) => {
    if (view_to_show === "teams-view") {
      setShowEnv(true)
      setShowMeeting(false)
    } else {
      setShowEnv(false)
      setShowMeeting(true)
    }
  }

  return (
    <div className="">
      <section id="main_wrapper" className="teams-view">
        {showMeeting ?
          <Meeting />
    : null
        }
        {showEnv ?
         <div className=" w-full flex ">
            <div class="w-4/5 ">
              <div id="page_1" className=" teams w-full h-full ">
                <Iframe url="https://demo.360-simulation.com/Environment%2028/"
                  width="100%"
                  id="teams"
                  className=""
                  height="100%"
                  loading={"...Please wait while loading your Meeting!"}
                />
              </div>
              </div>
            <div class="w-1/5  h-screen">
              {/* <FunctionalIFrameComponent title="functional-iframe">
                <Meeting />
              </FunctionalIFrameComponent> */}
              <Meeting />
            </div>
        </div>
      
          : null
        }
      </section>
    </div>
  )
}

export default Collapse
